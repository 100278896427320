
import { defineComponent } from "vue";

import style from "./style.module.less";

const list = [
  {
    title: "剧本杀",
    desc: "破案推理，千面人生",
    img: "/image/home/剧本杀@2x.png",
    titleColor: "#38DAF0",
  },
  {
    title: "Pia戏",
    desc: "Pia戏互动，戏感爆发",
    img: "/image/home/Pia戏@2x.png",
    titleColor: "#FFD244",
  },
  {
    title: "狼人杀",
    desc: "社交推理，相爱相杀",
    img: "/image/home/狼人杀@2x.png",
    titleColor: "#6C72FF",
  },
];

export default defineComponent({
  name: "Body",
  setup() {
    return () => (
      <div class={style.container}>
        {list.map((x) => (
          <div class={style.row} key={x.title}>
            <div class={style.text}>
              <div class={style.title} style={{ color: x.titleColor }}>
                {x.title}
              </div>
              <div class={style.desc}>{x.desc}</div>
            </div>
            <div
              class={style.img}
              style={{ backgroundImage: `url(${x.img})` }}
            ></div>
          </div>
        ))}
      </div>
    );
  },
});
