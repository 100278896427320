
import { defineComponent } from "vue";
import Layout from "@/components/layout/index.vue";

export default defineComponent({
  name: "App",
  setup() {
    return () => <Layout />;
  },
});
