
import { defineComponent } from "vue";
import Header from "./header/index.vue";
import style from "./style.module.less";
import Body from "./body/index.vue";

export default defineComponent({
  name: "Home",
  setup() {
    return () => (
      <div class={style.container}>
        <Header></Header>
        <Body></Body>
      </div>
    );
  },
});
