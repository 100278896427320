
import { defineComponent } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Pagination, Autoplay } from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/autoplay/autoplay.min.css";
import style from "./style.module.less";

const imgs = [
  "/image/home/img1@2x.png",
  "/image/home/img1@2x(1).png",
  "/image/home/img1@2x(2).png",
];

export default defineComponent({
  name: "Header",
  setup() {
    return () => (
      <div class={style.container}>
        <div>
          <Swiper
            class={style.swiper}
            modules={[Pagination, Autoplay]}
            pagination={{
              clickable: true,
              el: "#pagination",
              bulletClass: style["swiper-pagination-bullet"],
              bulletActiveClass: style["swiper-pagination-bullet-active"],
            }}
            loop
            autoplay={{
              delay: 5000,
            }}
          >
            {imgs.map((x) => (
              <SwiperSlide>
                <img src={x} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div id="pagination" class={style.pagination} />
        </div>
        <div class={style.right}>
          <div class={style.title}></div>
          <div class={style.download}>
            <div class={style.qrcode} />
            <div class={style.btnGroup}>
              <a
                class={style.btn}
                target="_blank"
                href="https://apps.apple.com/cn/app/%E6%88%91%E6%98%AF%E8%B0%9C-%E5%89%A7%E6%9C%AC%E6%9D%80-%E7%A0%B4%E6%A1%88%E6%8E%A8%E7%90%86%E5%BF%AB%E4%B9%90%E4%BA%A4%E5%8F%8B/id1388750151"
              >
                <i />
                IOS下载
              </a>
              <a
                class={style.btn}
                target="_blank"
                href="https://woshimiimage.xiyacs.com/app/apk/2.29.0/release.apk"
              >
                <i />
                安卓下载
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  },
});
