import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/home/index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/articles",
    name: "Articles",
    component: () =>
      import(/* webpackChunkName: "articles" */ "@/views/articles/index.vue"),
  },
  {
    path: "/contribute",
    name: "Contribute",
    component: () =>
      import(
        /* webpackChunkName: "contribute" */ "@/views/contribute/index.vue"
      ),
  },
  {
    path: "/store",
    name: "Store",
    component: () =>
      import(/* webpackChunkName: "store" */ "@/views/store/index.vue"),
  },
  {
    path: "/recharge",
    name: "Recharge",
    component: () =>
      import(/* webpackChunkName: "recharge" */ "@/views/recharge/index.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/about/index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
