
import { defineComponent } from "vue";
import { RouterLink, RouterView, useRoute } from "vue-router";

import style from "./style.module.less";

const menus = [
  {
    text: "首页",
    name: "Home",
  },
  // {
  //   text: "剧本",
  //   name: "Articles",
  // },
  {
    text: "投稿",
    name: "Contribute",
  },
  {
    text: "线下店",
    name: "Store",
  },
  {
    text: "关于我们",
    name: "About",
  },
];

const Navigation = defineComponent({
  name: "Navigation",
  setup() {
    const route = useRoute();
    return () => (
      <div class={style.nav}>
        <div class={style.menus}>
          <RouterLink to={{ name: "Home" }} class={style.logo}>
            <img src="/image/home/logo@2x.png" />
          </RouterLink>
          <div class={style.menuList}>
            {menus.map((x) => (
              <RouterLink
                to={{ name: x.name }}
                class={[
                  style.menuItem,
                  x.name === route.name ? style.active : null,
                ]}
              >
                {x.text}
              </RouterLink>
            ))}
          </div>
        </div>
        {/*  <RouterLink class={style.btn} to={{ name: "Recharge" }}>
          钻石充值
        </RouterLink> */}
      </div>
    );
  },
});

const Footer = defineComponent({
  name: "Footer",
  setup() {
    return () => (
      <div class={style.footer}>
        <div class={style.content}>
          <div class={style.item}>
            <div class={style.title}>投稿合作</div>
            <div class={style.links}>
              <a
                target="_blank"
                href="https://mp.weixin.qq.com/s/vC8i6YBCycszabR0EIPpNw"
              >
                剧本投稿
              </a>
              <a
                target="_blank"
                href="https://h5.xiyacs.com/mobile/announcement/1001"
              >
                剧本审核要求须知
              </a>
              <a
                target="_blank"
                href="https://mp.weixin.qq.com/s/vC8i6YBCycszabR0EIPpNw"
              >
                作者稿费奖励制度
              </a>
              <a target="_blank" href="/剧本模板.docx">
                剧本模板下载
              </a>
            </div>
          </div>
          <div class={style.item}>
            <div class={style.title}>联系方式</div>
            <div class={style.contact}>
              微信公众号：woshimi_app（我是谜App）
              <br />
              客服邮箱：woshimi@911tech.cn <br />
              商务合作：微信号lxxbani
            </div>
          </div>
          <div class={style.item}>
            <div class={style.title}>扫码关注</div>
            <div class={style.qrcode}></div>
          </div>
        </div>
        <div class={style.protocol}>
          <a target="_blank" href="https://woshimihtml.xiyacs.com/terms/6.html">
            《用户协议》
          </a>{" "}
          <a target="_blank" href="https://woshimihtml.xiyacs.com/terms/4.html">
            《隐私政策》
          </a>{" "}
          <a target="_blank" href="https://meitu.xiyacs.com/server_rules">
            《自动续费服务规则》
          </a>
        </div>
        <div class={style.copyright}>
          版权所有：copyright2021 长沙希芽科技有限公司&nbsp;
          <a target="_blank" href="https://beian.miit.gov.cn">湘ICP备2021015519号-{location.hostname === 'woshimi.cn' ? '2' : '1'}</a><br />
          增值电信业务经营许可证 湘B2-20220011 <br />
          地址：湖南湘江新区麓谷街道麓云路268号金悦雅苑二期14号楼A区2402
        </div>
      </div>
    );
  },
});

export default defineComponent({
  name: "Layout",
  setup() {
    return () => (
      <div class={style.container}>
        <Navigation></Navigation>
        <div class={[style.main, "layout-main"]}>
          <RouterView></RouterView>
        </div>
        <Footer></Footer>
      </div>
    );
  },
});
